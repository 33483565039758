import { createSlice } from "@reduxjs/toolkit";

const initState = [];

export const UserSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    setUser: (state, action) => action.payload,
    resetUser: () => initState,
  },
});

export const { setUser, resetUser } = UserSlice.actions;

export default UserSlice.reducer;
