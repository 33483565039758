const storeData = async (key, data) =>
  localStorage.setItem(key, JSON.stringify(data));
const getData = (key, callback) => {
  let data = localStorage.getItem(key);
  if (data) {
    callback(JSON.parse(data));
  } else {
    callback(false);
  }
};
const clearStorage = () => !!localStorage.clear();
const removeItem = (item) => localStorage.removeItem(item);

const removeItemsFromLocalStorage = async (keys) => {
  keys.forEach(async (key) => {
    try {
      localStorage.removeItem(key);
      return true;
    } catch (exception) {
      return false;
    }
  });
};

export {
  storeData,
  getData,
  clearStorage,
  removeItemsFromLocalStorage,
  removeItem,
};
