import { createStore, combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import change from "./reducer/changReducer";
import LoginSlice from "./reducer/loginSlice";
import homefeed from "./reducer/homefeedSlice";
import commentSlice from "./reducer/commentSlice";
import gameReviewsSlice from "./reducer/gameReviewSlice";
import querySlice from "./reducer/queryReducer";
import userSlice from "./reducer/userReducer";
import replyReportSlice from "./reducer/replyReportSlice";
import userProfileSlice from "./reducer/userProfileReducer";
import VerifyUserslice from "./reducer/verifyReducer";
const rootReducer = combineReducers({
  User: LoginSlice,
  toogle: change,
  homeFeed: homefeed,
  comment: commentSlice,
  gameReviews: gameReviewsSlice,
  query: querySlice,
  listUser: userSlice,
  replyReport: replyReportSlice,
  userProfile: userProfileSlice,
  verifyUser: VerifyUserslice,
});

export const store = configureStore({
  reducer: {
    root: rootReducer,
  },
});
