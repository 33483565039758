import { createSlice } from "@reduxjs/toolkit";

const initState = [];

export const VerifyUserSlice = createSlice({
  name: "verify user",
  initialState: initState,
  reducers: {
    setVerifyUser: (state, action) => action.payload,
    resetVerifyUser: () => initState,
  },
});

export const { setVerifyUser, resetVerifyUser } = VerifyUserSlice.actions;

export default VerifyUserSlice.reducer;
