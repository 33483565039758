import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sidebarShow: "responsive",
};

export const changeStateSlice = createSlice({
  name: "change",
  initialState: initialState,
  reducers: {
    changeState: (state, action) => action.payload,

    resetState: () => initialState,
  },
});

export const { changeState, resetState } = changeStateSlice.actions;

export default changeStateSlice.reducer;
