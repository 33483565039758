import { createSlice } from "@reduxjs/toolkit";

const initState = "";

export const QuerySlice = createSlice({
  name: "query",
  initialState: initState,
  reducers: {
    setQuery: (state, action) => action.payload,
    resetQuery: () => initState,
  },
});

export const { setQuery, resetQuery } = QuerySlice.actions;

export default QuerySlice.reducer;
