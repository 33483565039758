import { createSlice} from "@reduxjs/toolkit";

const initialState = {};
const userProfileSlice = createSlice({
    name:"userProfile",
    initialState: initialState,
    reducers:{
        setProfile : (state,action)=> action.payload,
        resetProfile: ()=> initialState
    }
});
export const { setProfile,resetProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;