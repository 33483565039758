import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
export const replyReportSlice = createSlice({
  name: "Reply",
  initialState: initialState,
  reducers: {
    setReplyReport: (state, action) => action.payload,
    resetReply: (state, action) => initialState,
  },
});
export const { setReplyReport, resetReply } = replyReportSlice.actions;
export default replyReportSlice.reducer;
