import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./scss/style.scss";

import { loginUser } from "./redux/reducer/loginSlice";
import { getData } from "./util/AppStorage";
import "react-toastify/dist/ReactToastify.css";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  let dispatch = useDispatch();
  React.useEffect(() => {
    getData("user", (data) => {
      if (data) {
        dispatch(loginUser(data));
      }
    });
  }, []);
  const { User } = useSelector((state) => state.root);
  var isAuthenticated = User?.token || false;
  var AdminRole = User?.role;
  var PrivateRoute = (
    <Switch>
      <Route
        exact
        path="/"
        name="Login Page"
        render={(props) => <Login {...props} />}
      />

      <Route
        exact
        path="/404"
        name="Page 404"
        render={(props) => <Page404 {...props} />}
      />
      <Route
        exact
        path="/500"
        name="Page 500"
        render={(props) => <Page500 {...props} />}
      />
      {/* <Redirect to="/" /> */}
    </Switch>
  );
  var PublicRoute = (
    <Switch>
      <Route
        path="/"
        name="Home"
        render={(props) => <TheLayout {...props} />}
      />
    </Switch>
  );
  return (
    <Router>
      <React.Suspense fallback={loading}>
        {isAuthenticated && AdminRole === "ADMIN" ? (
          PublicRoute
        ) : (
          <>{PrivateRoute}</>
        )}
      </React.Suspense>
    </Router>
  );
};

export default App;
