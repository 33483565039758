import { createSlice } from "@reduxjs/toolkit";

const initState = [];

export const CommentSlice = createSlice({
  name: "comment",
  initialState: initState,
  reducers: {
    setComment: (state, action) => action.payload,
    resetComment: () => initState,
  },
});

export const { setComment, resetComment } = CommentSlice.actions;

export default CommentSlice.reducer;
