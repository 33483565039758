import { createSlice } from "@reduxjs/toolkit";
const initState = "";
export const LoginSlice = createSlice({
  name: "login",
  initialState: initState,
  reducers: {
    loginUser: (state, action) => action.payload,
    resetUser: () => initState,
  },
});
export const { loginUser, resetUser } = LoginSlice.actions;
export default LoginSlice.reducer;
